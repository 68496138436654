import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import SessionContext from 'App/Contexts/Session';

const Protected = ({ component: Component, ...rest }) => {
  const session = useContext(SessionContext);

  if (session.authenticated) {
    // Render component if current menu available from permissions
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  return <Redirect to={{ pathname: '/auth/login' }} />;
};

Protected.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Protected;
