/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */

/**
  * Get Breakpoint
  * @param {Object} screens - Grid.useBreakpoint() from antd
  * @return {Array} array of breakpoint size
  */

const getBreakpoint = (screens) => {
  const breakpoints = [];

  for (const key in screens) {
    if (screens.hasOwnProperty(key)) {
      const element = screens[key];
      if (element) {
        breakpoints.push(key);
      }
    }
  }

  return breakpoints;
};

export default getBreakpoint;
