import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getBreakpoint from 'App/Utils/getBreakpoint';
import { convertToKebabCase } from 'App/Utils/Filter';
import UIContext from 'App/Contexts/UI';

import { Menu, Grid } from 'antd';
import * as AntdIcons from '@ant-design/icons';

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const MenuContent = ({ history, menu }) => {
  const ui = useContext(UIContext);
  const { t } = useTranslation();
  const isMobile = !getBreakpoint(useBreakpoint()).includes('lg');

  const [current, setCurrent] = useState('dashboard-daily');
  const [currentSubMenu, setCurrentSubMenu] = useState(['dashboard']);

  const closeMobileNav = () => {
    if (isMobile) {
      ui.onMobileNavToggle();
    }
  };

  const onSelect = (item) => {
    const { item: { props: { url } }, key } = item;

    if (url) {
      history.push(url);
      setCurrent(key);
      closeMobileNav();
    }
  };

  const onOpenChange = (keys) => {
    const rootSubmenuKeys = menu?.filter((item) => item.parent_id === 'None').map(({ name }) => convertToKebabCase(name)) || [];
    const latestOpenKey = keys.find((key) => currentSubMenu.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setCurrentSubMenu(keys);
    } else {
      setCurrentSubMenu(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Menu
      mode='inline'
      theme='dark'
      selectedKeys={[current]}
      onSelect={onSelect}
      openKeys={currentSubMenu}
      onOpenChange={onOpenChange}
      style={{ height: '100%', borderRight: 0 }}
    >
      {menu.map((item) => (
        item.sub_menu.length > 0 ? (
          <SubMenu
            key={convertToKebabCase(item.name)}
            icon={item.icon && React.createElement(AntdIcons[item.icon])}
            title={t(item.name)}
          >
            {item.sub_menu.map((child) => (
              child.sub_menu.length > 0 ? (
                <SubMenu
                  key={convertToKebabCase(child.name)}
                  icon={child.icon && React.createElement(AntdIcons[child.icon])}
                  title={t(child.name)}
                >
                  {child.sub_menu.map((item1) => (
                    <Menu.Item key={convertToKebabCase(`${child.name} ${item1.name}`)} url={item1.url}>
                      {t(item1.name)}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={convertToKebabCase(`${item.name} ${child.name}`)} url={child.url}>
                  {t(child.name)}
                </Menu.Item>
              )
            ))}
          </SubMenu>
        ) : (
          <Menu.Item key={convertToKebabCase(item.name)} url={item.url}>
            {t(item.name)}
          </Menu.Item>
        )
      ))}
    </Menu>
  );
};

MenuContent.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  menu: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default MenuContent;
