/* eslint-disable */
import _ from 'lodash';

export const filterCost = (value) => {
  const labels = [];
  const series = [];

  for (var key in value) {
    if (!key.startsWith('total_')) {
      labels.push(_.startCase(_.camelCase(key)));
      series.push(parseFloat(value[key]));
    }
  }

  const total = series.reduce((a, b) => {
    return a + b;
  }, 0);

  return { labels, series, total };
};

export const filterTotalProduction = (value, type = 'daily_heatmap') => {
  const data = {
    coal_getting: _.sumBy(value[type], ((item) => {
      return parseInt(item.coal_getting, 10);
    })),
    coal_getting_goal_num: _.sumBy(value[type], ((item) => {
      return parseInt(item.coal_getting_goal_num, 10);
    })),
    coal_getting_goal_percentage: `${_.sumBy(value[type], ((item) => {
      return parseFloat(item.coal_getting_goal_percentage || 0);
    }))}%`,
    ob_removal: _.sumBy(value[type], ((item) => {
      return parseInt(item.ob_removal, 10);
    })),
    ob_removal_goal_num: _.sumBy(value[type], ((item) => {
      return parseInt(item.ob_removal_goal_num, 10);
    })),
    ob_removal_goal_percentage: `${_.sumBy(value[type], ((item) => {
      return parseFloat(item.ob_removal_goal_percentage || 0);
    }))}%`,
  };

  return data;
};

export const filterTotalSales = (value, type = 'daily_heatmap') => {
  const data = {
    total_sales: _.sumBy(value[type], ((item) => {
      return parseInt(item.total_sales, 10);
    })),
    total_sales_goal_num: _.sumBy(value[type], ((item) => {
      return parseInt(item.total_sales_goal_num, 10);
    })),
    total_sales_goal_percentage: `${_.sumBy(value[type], ((item) => {
      return parseFloat(item.total_sales_goal_percentage || 0);
    }))}%`,
    revenue: _.sumBy(value[type], ((item) => {
      return parseInt(item.revenue, 10);
    })),
    revenue_goal_num: _.sumBy(value[type], ((item) => {
      return parseInt(item.revenue_goal_num, 10);
    })),
    revenue_goal_percentage: `${_.sumBy(value[type], ((item) => {
      return parseFloat(item.revenue_goal_percentage || 0);
    }))}%`,
  };

  return data;
};

export const filterHeatmap = (contractor, data) => {
  const result = [];

  contractor.map((item) => {
    const dailyHeatmap = data?.daily_heatmap?.find((daily) => daily.contractor_id === item.id);
    const weeklyHeatmap = data?.weekly_heatmap?.find((weekly) => weekly.contractor_id === item.id);
    const monthlyHeatmap = data?.monthly_heatmaps?.find((monthly) => monthly.contractor_id === item.id);
    const yearlyHeatmap = data?.yearly_heatmaps?.find((yearly) => yearly.contractor_id === item.id);
    const weeklyGraph = data?.weekly_graph?.find((weekly) => weekly.contractor_id === item.id);
    const monthlyGraph = data?.monthly_graph?.find((monthly) => monthly.contractor_id === item.id);
    const yearlyGraph = data?.yearly_graph?.find((yearly) => yearly.contractor_id === item.id);

    result.push({
      contractor_id: item.id,
      contractor_name: item.name,
      daily: dailyHeatmap,
      weekly: weeklyHeatmap,
      monthly: monthlyHeatmap,
      yearly: yearlyHeatmap,
      weekly_graph: weeklyGraph,
      monthly_graph: monthlyGraph,
      yearly_graph: yearlyGraph,
    });
  });

  return result;
};

export const filterSurvey = (data) => {
  const result = [];

  const dailySurvey = data?.daily_survey?.find((daily) => daily.company_id);
  const weeklySurvey = data?.weekly_survey?.find((weekly) => weekly.company_id);
  const monthlySurvey = data?.monthly_survey?.find((monthly) => monthly.company_id);
  const yearlySurvey = data?.yearly_survey?.find((yearly) => yearly.company_id);
  const weeklyGraph = data?.weekly_graph?.find((weekly) => weekly.company_id);
  const monthlyGraph = data?.monthly_graph?.find((monthly) => monthly.company_id);
  const yearlyGraph = data?.yearly_graph?.find((yearly) => yearly.company_id);


  // company.map((item) => {
  //   const dailySurvey = data?.daily_survey?.find((daily) => daily.company_id === item.id);
  //   const weeklySurvey = data?.weekly_survey?.find((weekly) => weekly.company_id === item.id);
  //   const monthlySurvey = data?.monthly_survey?.find((monthly) => monthly.company_id === item.id);
  //   const yearlySurvey = data?.yearly_survey?.find((yearly) => yearly.company_id === item.id);
  //   const weeklyGraph = data?.weekly_graph?.find((weekly) => weekly.company_id === item.id);
  //   const monthlyGraph = data?.monthly_graph?.find((monthly) => monthly.company_id === item.id);
  //   const yearlyGraph = data?.yearly_graph?.find((yearly) => yearly.company_id === item.id);

    result.push({
      // company_id: item.id,
      // company_name: item.name,
      daily: dailySurvey,
      weekly: weeklySurvey,
      monthly: monthlySurvey,
      yearly: yearlySurvey,
      weekly_graph: weeklyGraph,
      monthly_graph: monthlyGraph,
      yearly_graph: yearlyGraph,
    });

  return result;
};

export const filterMenu = (data) => {
  const result = [];

  data.map((item) => {
    result.push(item);

    if (item.sub_menu.length > 0) {
      item.sub_menu.map((child) => {
        result.push(child);

        if (child.sub_menu.length > 0) {
          child.sub_menu.map((item2) => {
            result.push(item2);
          });
        }
      });
    }
  });

  return result;
};

export const sortMenu = (data) => {
  const result = [];

  data.map((item) => {
    item.sub_menu.sort(function(a, b) {
      return parseInt(a.order) - parseInt(b.order);
    });
    result.push(item);
  });

  return result;
};

export const convertToKebabCase = (string) => {
  return string.replace(/\s+/g, '-').toLowerCase();
};

export const filterSalesBuyer = (data) => {
  const result = [];

  Object.entries(data).forEach(([key, value]) => {
    value.map((item) => {
      result.push({
        date: key,
        ...item,
      });
    });
  });

  return result;
};

export const filterEquipment = (data) => {
  const result = [];

  data.map((item) => {
    const name = Object.keys(item);

    result.push({
      name: name[0],
      general: item[name[0]].general,
      co: item[name[0]].co,
      ob: item[name[0]].ob,
      haul: item[name[0]].haul,
      rom: item[name[0]].rom,
      standby: item[name[0]].standby,
      total_used: item[name[0]].total_used,
    });
  });

  return result;
};

export const orderByDate = (data) => {
  const result = data.sort((a, b) => {
    let c = new Date(a.date);
    let d = new Date(b.date);

    return c-d;
  });

  return result;
};

export const filterWeather = (data) => {
  let result = [];

  for (let index = 0; index < data.length; index++) {
    for (const [key, value] of Object.entries(data[index])) {
      for (let indexWeather = 0; indexWeather < value.length; indexWeather++) {
        result.push({
          contractor: key,
          ...value[indexWeather],
        });
      }
    }
  }

  return result;
};