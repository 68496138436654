import React from 'react';
import PropTypes from 'prop-types';
import urlBuilder from 'App/Hooks/useUrlBuilder';

import { Grid, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import getBreakpoint from 'App/Utils/getBreakpoint';

import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
} from 'App/Constants/ThemeConstant';

import logo_default from 'App/Assets/Images/logo-white.svg';
import logo_sm from 'App/Assets/Images/logo-sm.svg';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = ({ collapsed, isMobile, mobileLogo }) => {
  if (isMobile && !mobileLogo) {
    return 0;
  }

  if (collapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  }

  return `${SIDE_NAV_WIDTH}px`;
};

const getLogo = ({ collapsed, isMobile }) => {
  if (isMobile) {
    return logo_default;
  }

  if (collapsed) {
    return logo_sm;
  }

  return logo_default;
};

const getLogoDisplay = (isMobile, mobileLogo, collapsed) => {
  if (isMobile && !mobileLogo) {
    return 'd-none';
  }

  if (!isMobile && collapsed) {
    return 'logo-collapsed';
  }

  return 'logo';
};

const Logo = ({ collapsed, mobileLogo, loading, logo }) => {
  const isMobile = !getBreakpoint(useBreakpoint()).includes('lg');
  const logo_url = urlBuilder(`api/master-data/logo/file/${logo?.file}`);

  return (
    <div
      className={getLogoDisplay(isMobile, mobileLogo, collapsed)}
      style={{ width: `${getLogoWidthGutter({ collapsed, isMobile, mobileLogo })}` }}
    >
      {loading ? (
        <Skeleton.Button size='small' shape='round' active block />
      ) : (
        <Link to='/dashboard/daily'>
          <img
            src={logo ? logo_url : getLogo({ collapsed, isMobile })}
            className='img-fluid'
            alt='Qatalyst logo'
            style={{ height: collapsed ? 45 : 40 }}
          />
        </Link>
      )}
    </div>
  );
};

Logo.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  mobileLogo: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  logo: PropTypes.string,
};

Logo.defaultProps = {
  mobileLogo: false,
  logo: null,
};

export default Logo;
