import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import UIContext from 'App/Contexts/UI';
import SessionContext from 'App/Contexts/Session';

import useCoal from 'App/Hooks/useCoal';
import useOpenWeather from 'App/Hooks/useOpenWeather';
import useAxios from 'App/Hooks/useAxios';

import { Layout, Grid } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import HeaderNav from 'App/Components/Layout/HeaderNav';
import SideNav from 'App/Components/Layout/SideNav';
import MobileNav from 'App/Components/Layout/MobileNav';
import Footer from 'App/Components/Layout/Footer';
import ChangePassword from 'App/Components/ChangePassword';

import Routes from 'App/Routes';

import bg from 'App/Assets/Images/background-app.png';

import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH } from 'App/Constants/ThemeConstant';
import getBreakpoint from 'App/Utils/getBreakpoint';

import { API_OPEN_WEATHER } from '../../../config';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const BaseLayout = ({ history }) => {
  const { apiKey } = API_OPEN_WEATHER;

  const ui = useContext(UIContext);
  const session = useContext(SessionContext);
  const screens = getBreakpoint(useBreakpoint());
  const isMobile = !screens.includes('lg');

  const [response, setResponse] = useState({
    kurs: {},
    weather: {},
    logo: null,
    menu: [],
  });

  const [menuState, menuSend] = useAxios({
    url: 'api/menu/user/',
  });

  const [coalPriceState, coalPriceSend] = useCoal();

  const [weatherState, weatherSend] = useOpenWeather();

  const fetch = () => {
    menuSend({}, (res) => {
      setResponse((prevState) => ({
        ...prevState,
        menu: res || [],
      }));

      ui.onSetPermission(res || []);
    });

    coalPriceSend({}, (res) => {
      setResponse((prevState) => ({
        ...prevState,
        kurs: res,
      }));
    });

    weatherSend({
      params: {
        appid: apiKey,
        id: '1650357',
        units: 'metric',
      },
    }, (res) => {
      setResponse((prevState) => ({
        ...prevState,
        weather: res,
      }));
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  const getLayoutGutter = () => {
    if (isMobile) {
      return 15;
    }

    return ui.collapsedMenu ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const getLayoutDirectionGutter = () => {
    return {
      margin: `70px 15px 15px ${getLayoutGutter()}px`,
    };
  };

  const [password, setPassword] = useState({
    visible: false,
    id: null,
  });

  const openChangePassword = () => {
    setPassword({
      visible: true,
      id: session.email,
    });
  };

  return (
    <Layout>
      <HeaderNav
        isMobile={isMobile}
        loadingCoal={coalPriceState.isFetching}
        kurs={response.kurs}
        loadingWeather={weatherState.isFetching}
        weather={response.weather}
        loadingLogo={menuState.isFetching}
        logo={session?.logo}
      />
      <Layout className='app-container'>
        {(!isMobile) ? (
          <SideNav
            history={history}
            loading={menuState.isFetching}
            menu={response.menu}
            openChangePassword={openChangePassword}
          />
        ) : null}
        <Layout className='app-layout' style={getLayoutDirectionGutter()}>
          <div className='app-content'>
            <Scrollbars>
              <Content>
                <Routes />
              </Content>
            </Scrollbars>
          </div>
          <Footer />
        </Layout>
      </Layout>
      <div className='background-top' style={{ backgroundImage: `url(${bg})` }} />
      {isMobile && (
        <MobileNav
          history={history}
          loading={menuState.isFetching}
          loadingLogo={menuState.isFetching}
          logo={session?.logo}
          menu={response.menu}
          openChangePassword={openChangePassword}
        />
      )}
      <ChangePassword
        visible={password.visible}
        id={password.id}
        onClose={() => {
          setPassword({
            visible: false,
            id: null,
          });
        }}
      />
    </Layout>
  );
};

BaseLayout.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BaseLayout;
