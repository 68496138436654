import React from 'react';

import { Space, Skeleton } from 'antd';

const MenuLoading = () => {
  return (
    <div className='p-3'>
      {[...Array(6)].map((item, index) => (
        <Space className='mb-3' key={index}>
          <Skeleton.Avatar shape='square' size='small' active />
          <Skeleton.Input style={{ width: 165 }} size='small' active />
        </Space>
      ))}
    </div>
  );
};

export default MenuLoading;
