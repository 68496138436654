import { useState } from 'react';
import axios from 'axios';

import { message as notification } from 'antd';

import { API_COAL } from '../../config';

const useCoal = () => {
  const { urlApi } = API_COAL;

  const initialState = {
    isFetching: false,
    isError: false,
    statusCode: null,
    message: null,
  };

  // Define state first
  const [state, setState] = useState(initialState);

  // Define url
  const url = urlApi;

  // Define default arguments
  const args = {
    method: 'GET',
    url,
    data: null,
    params: {},
    headers: {},
  };

  // Define request headers
  const headers = {
    Accept: 'application/json',
  };

  // Set params headers
  args.headers = headers;

  // Define request send method
  // $FlowFixMe
  const send = (_args = {}, successCb = undefined, errorCb = undefined) => {
    // send http request
    // Set isFetching to true, for loading purpose
    setState({
      ...initialState,
      isFetching: true,
    });

    // send request to server
    setTimeout(() => {
      axios({
        crossDomain: true,
        ...args,
        ..._args,
      })
        .then((response) => {
          if (response.data) {
            setState((prevState) => ({
              ...prevState,
              statusCode: response.status,
              data: response.data,
            }));

            if (successCb) successCb(response.data);
          }
        })
        .catch((error) => {
          let statusCode = 500;
          let message = 'HTTP Request Error';

          if (error.response) {
            statusCode = error.response?.status || 500;
            message = error.response?.data?.message;
          }

          notification.error(`(${statusCode}) ${message}`);

          setState((prevState) => ({
            ...prevState,
            isError: true,
            statusCode,
            message,
          }));

          if (errorCb) errorCb({ statusCode, message });
        })
        .then(() => {
          setState((prevState) => ({
            ...prevState,
            isFetching: false,
          }));
        });
    }, 2000);
  };

  return [state, send];
};

export default useCoal;
