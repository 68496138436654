import { API } from '../../config';

const serialize = (obj) => {
  if (obj !== {}) {
    const entries = Object.entries(obj);

    return entries
      .filter((entry) => entry[1] !== null)
      .map((entry) => entry.reduce((a, b) => `${encodeURI(a)}=${encodeURI(b)}`))
      .join('&');
  }

  return null;
};

const urlConfig = (secure = false, host, port = null, path = null) => {
  let apiPort;
  let apiPath;
  const proto = secure ? 'https://' : 'http://';

  let url = `${proto}${host}`;

  if (port) {
    apiPort = `:${port}`;
    url += apiPort;
  }

  if (path) {
    apiPath = `/${path}`;
    url += apiPath;
  }

  url += '/';

  return url;
};

const urlBuilder = (resource = null, params = undefined) => {
  const { secure, host, path, port = undefined } = API;

  let url = urlConfig(secure, host, port, path);

  if (resource) url += `${resource}`;

  if (params) url += `?${serialize(params)}`;

  return url;
};

export default urlBuilder;
