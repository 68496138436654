import React from 'react';
import PropTypes from 'prop-types';
import useAxios from 'App/Hooks/useAxios';
import { useTranslation } from 'react-i18next';

import { Modal, Form, Input, Button, Spin } from 'antd';

import RegexPattern from 'App/Utils/RegexPattern';

const ChangePassword = ({ visible, id, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [updateState, updateSend] = useAxios({
    url: `api/users/${id}/changepassword`,
    method: 'PUT',
  });

  const handleSubmit = (values) => {
    updateSend({ data: values }, () => {
      onClose();
    });
  };

  return (
    <Modal
      visible={visible}
      title={t('Change Password')}
      onCancel={onClose}
      width={500}
      style={{ paddingBottom: 0 }}
      afterClose={form.resetFields}
      centered
      footer={null}
    >
      <Spin spinning={updateState.isFetching}>
        <Form layout='vertical' onFinish={handleSubmit}>
          <Form.Item
            label={t('Password')}
            name='password'
            className='mb-4'
            rules={[
              { pattern: new RegExp(RegexPattern.password), message: t('validation:invalid', { field: t('Password') }) },
              { required: true, message: t('validation:required', { field: t('Password') }) },
            ]}
          >
            <Input.Password placeholder={t('placeholder:enter', { field: t('Password') })} autoFocus />
          </Form.Item>
          <Form.Item className='text-right mb-0'>
            <Button type='primary' htmlType='submit'>
              {t('button:submit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

ChangePassword.propTypes = {
  visible: PropTypes.bool.isRequired,
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

ChangePassword.defaultProps = {
  id: null,
};

export default ChangePassword;
