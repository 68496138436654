import { useState, useContext } from 'react';
import axios from 'axios';

import { message as notification } from 'antd';

// Import contexts
import SessionContext from 'App/Contexts/Session';

// Import hooks
import useUrlBuilder from './useUrlBuilder';

const useAxios = ({
  method = 'GET',
  url: _url,
  headers: _headers = {},
  options: _options = {},
}) => {
  const initialState = {
    isFetching: false,
    isError: false,
    statusCode: null,
    message: null,
  };

  // Get context session
  const session = useContext(SessionContext);

  // Define state first
  const [state, setState] = useState(initialState);

  // Define url
  const url = useUrlBuilder(_url);

  // Define default arguments
  const args = {
    method,
    url,
    data: null,
    params: {},
    headers: {},
  };

  const options = {
    authenticated: true,
    authenticationPrefix: 'Bearer',
    withNotification: true,
    ..._options,
  };

  // Define request headers
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ..._headers,
  };

  // Set authorization headers if request is authenticated
  if (options.authenticated && session.token) {
    headers.Authorization = `${options.authenticationPrefix} ${session.token}`;
  }

  // Set params headers
  args.headers = headers;

  // Define request send method
  // $FlowFixMe
  const send = (_args = {}, successCb = undefined, errorCb = undefined) => {
    // send http request
    // Set isFetching to true, for loading purpose
    setState({
      ...initialState,
      isFetching: true,
    });

    // send request to server
    setTimeout(() => {
      axios({
        crossDomain: true,
        // withCredentials: true, // to make express session id persistent
        ...args,
        ..._args,
      })
        .then((response) => {
          if (response.data) {
            setState((prevState) => ({
              ...prevState,
              statusCode: response.status,
              data: response.data,
            }));

            if (args.method !== 'GET' && options.withNotification) {
              notification.success(response.data.message);
            }

            if (successCb) successCb(response.data);
          }
        })
        .catch((error) => {
          let statusCode = 500;
          let message = 'HTTP Request Error';

          if (error.response) {
            statusCode = error.response?.status || 500;
            message = error.response?.data?.message;
          }

          if (statusCode === 401) {
            session.setSession({
              authenticated: false,
              token: null,
              email: null,
              first_name: null,
              last_name: null,
              company_id: null,
              role: null,
            });

            localStorage.removeItem('token');
            localStorage.removeItem('email');
          }

          notification.error(`(${statusCode}) ${message}`);

          setState((prevState) => ({
            ...prevState,
            isError: true,
            statusCode,
            message,
          }));

          if (errorCb) errorCb({ statusCode, message });
        })
        .then(() => {
          setState((prevState) => ({
            ...prevState,
            isFetching: false,
          }));
        });
    }, 2000);
  };

  return [state, send];
};

export default useAxios;
