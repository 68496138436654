import React from 'react';
import { useTranslation } from 'react-i18next';
import useAxios from 'App/Hooks/useAxios';

import { Form, Input, Button, Typography } from 'antd';
import { Link } from 'react-router-dom';

import './Auth.less';

import bg from 'App/Assets/Images/background-art.png';
import logo from 'App/Assets/Images/logo-white.svg';

const { Title, Text, Paragraph } = Typography;

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [forgotState, forgotSend] = useAxios({
    url: 'auth-api/forget-password',
    method: 'POST',
  });

  const onSubmit = (values) => {
    forgotSend({ data: values }, () => {
      form.resetFields();
    });
  };

  return (
    <div className='auth-wrapper'>
      <div className='background'>
        <img src={bg} className='img-fluid' alt='Background' />
      </div>
      <div className='container-fluid vh-100 pt-4'>
        <div className='row'>
          <div className='col-12 mb-5'>
            <img
              src={logo}
              alt='Qatalyst logo'
              width={170}
            />
          </div>
          <div className='col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-1 col-xl-3 offset-xl-1 mt-5'>
            <Title level={1} className='mb-1'>Forgot Password</Title>
            <Text>Reset your password by sending a link through your email</Text>
            <Form form={form} layout='vertical' className='login-form' onFinish={onSubmit}>
              <Form.Item
                name='email'
                className='mb-3'
                rules={[
                  { type: 'email', message: t('validation:invalid', { field: t('Email') }) },
                  { required: true, message: t('validation:required', { field: t('Email') }) },
                ]}
              >
                <Input
                  placeholder={t('placeholder:enter', { field: t('Email') })}
                  type='email'
                  autoFocus
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  loading={forgotState.isFetching}
                  block
                >
                  Send Reset Link
                </Button>
              </Form.Item>
              <Paragraph className='text-center'>
                <Link to='/auth/login'>Back to Login</Link>
              </Paragraph>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
