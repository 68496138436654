export const API = {
  host: 'qatalyst.qti.co.id',
  secure: true,
};

export const API_OPEN_WEATHER = {
  urlApi: 'https://api.openweathermap.org/data/2.5/weather',
  apiKey: 'd2912c396b41f409abfe74747249040b',
};

export const API_COAL = {
  urlApi: 'https://be-dashboard.qti.co.id/qatalyst/batu-bara',
};

export const API_KEY_GOOGLE = 'AIzaSyAiK8nxKcNKsIgPmYPC-5HQWFpNu5HEa-E';