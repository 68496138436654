import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import style
import './style.less';

function Loading({ isFull }) {
  const { t } = useTranslation();

  return (
    <div className={`spin-container ${isFull ? 'full' : ''}`}>
      <div className='spinner'>
        <div className='double-bounce1' />
        <div className='double-bounce2' />
      </div>
      <div className='loading-text'>{t('Loading')}</div>
    </div>
  );
}

Loading.propTypes = {
  isFull: PropTypes.bool,
};

Loading.defaultProps = {
  isFull: true,
};

export default Loading;
