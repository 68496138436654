import { createContext } from 'react';

const SessionContext = createContext({
  authenticated: false,
  token: null,
  email: null,
  first_name: null,
  last_name: null,
  company_id: null,
  role: null,
  setSession: () => {},
});

export default SessionContext;
