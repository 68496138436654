import { createContext } from 'react';

const UIContext = createContext({
  collapsedMenu: false,
  mobileNav: false,
  permission: [],
  setUI: () => {},
});

export default UIContext;
