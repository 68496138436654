import React from 'react';

export default function Footer() {
  return (
    <footer className='footer'>
      <span>
        Copyright &copy;
        {new Date().getFullYear()}
        <span className='font-weight-semibold'> PT. Quantus Telematika Indonesia. </span>
        All rights reserved.
      </span>
    </footer>
  );
}
