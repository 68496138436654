import React, { useState } from 'react';
import PropTypes from 'prop-types';

import UIContext from 'App/Contexts/UI';

function UIContextProvider({ children }) {
  const [state, setState] = useState({
    collapsedMenu: false,
    mobileNav: false,
    permission: [],
  });

  const onCollapseNav = (collapsedMenu) => {
    setState((prevState) => ({
      ...prevState,
      collapsedMenu,
    }));
  };

  const onMobileNavToggle = (mobileNav) => {
    setState((prevState) => ({
      ...prevState,
      mobileNav,
    }));
  };

  const onSetPermission = (permission) => {
    setState((prevState) => ({
      ...prevState,
      permission,
    }));
  };

  return (
    <UIContext.Provider
      value={{
        ...state,
        onCollapseNav,
        onMobileNavToggle,
        onSetPermission,
      }}
    >
      {children}
    </UIContext.Provider>
  );
}

UIContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UIContextProvider;
