import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAxios from 'App/Hooks/useAxios';

import { Form, Input, Button, Typography } from 'antd';

import './Auth.less';

import bg from 'App/Assets/Images/background-art.png';
import logo from 'App/Assets/Images/logo-white.svg';

const { Title, Text } = Typography;

const ResetPassword = ({ match, history }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { _key } = match.params;

  const [resetState, resetSend] = useAxios({
    url: 'auth-api/reset-password',
    method: 'POST',
  });

  useEffect(() => {
    if (!_key) history.push('/auth/login');
  }, [_key]);

  const onSubmit = (values) => {
    const data = {
      resetkey: _key,
      new_password: values.new_password,
    };

    resetSend({ data }, () => {
      history.push('/auth/login');
    });
  };

  return (
    <div className='auth-wrapper'>
      <div className='background'>
        <img src={bg} className='img-fluid' alt='Background' />
      </div>
      <div className='container-fluid vh-100 pt-4'>
        <div className='row'>
          <div className='col-12 mb-5'>
            <img
              src={logo}
              alt='Qatalyst logo'
              width={170}
            />
          </div>
          <div className='col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-1 col-xl-3 offset-xl-1 mt-5'>
            <Title level={1} className='mb-1'>Reset Password</Title>
            <Text>Your new password must be different from previous password.</Text>
            <Form form={form} layout='vertical' className='login-form' onFinish={onSubmit}>
              <Form.Item
                name='new_password'
                className='mb-3'
                rules={[
                  { min: 8, message: t('validation:min-char', { field: t('New Password'), number: 8 }) },
                  { whitespace: true, message: t('validation:whitespace', { field: t('New Password') }) },
                  { required: true, message: t('validation:required', { field: t('New Password') }) },
                ]}
                hasFeedback
              >
                <Input.Password
                  type='password'
                  placeholder={t('New Password')}
                  autoFocus
                />
              </Form.Item>
              <Form.Item
                name='password_confirm'
                dependencies={['new_password']}
                className='mb-3'
                rules={[
                  { required: true, message: t('validation:required', { field: t('Confirm Password') }) },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password
                  type='password'
                  placeholder={t('Confirm Password')}
                />
              </Form.Item>
              <Form.Item className='mb-0'>
                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  loading={resetState.isFetching}
                  block
                >
                  {t('Reset Password')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ResetPassword;
