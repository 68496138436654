import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Protected } from 'App/Components/Routes';

import { Login, ForgotPassword, ResetPassword } from 'App/Containers/Auth';
import BaseLayout from 'App/Containers/BaseLayout';

import ScrollToTop from 'App/Components/ScrollToTop';

import 'App/Assets/Locales/i18n';

import 'App/Assets/CSS/theme.less';
import 'App/Assets/CSS/grid-systems.less';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path='/auth/login' exact component={Login} />
        <Route path='/auth/forgot-password' exact component={ForgotPassword} />
        <Route path='/auth/reset-password/:_key' exact component={ResetPassword} />
        <Protected path='/' component={BaseLayout} />
      </Switch>
    </Router>
  );
};

export default App;
