import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAxios from 'App/Hooks/useAxios';

import SessionContext from 'App/Contexts/Session';

import { Form, Input, Button, Typography } from 'antd';
import { Link } from 'react-router-dom';

import './Auth.less';

import bg from 'App/Assets/Images/background-art.png';
import logo from 'App/Assets/Images/logo-white.svg';

const { Title, Text } = Typography;

const Login = ({ history }) => {
  const { t } = useTranslation();
  const session = useContext(SessionContext);

  useEffect(() => {
    if (session.authenticated) {
      history.push('/dashboard/daily');
    }
  }, [session]);

  const [loginState, loginSend] = useAxios({
    url: 'auth-api/login',
    method: 'POST',
  });

  const onSubmit = (values) => {
    loginSend({ data: values }, (res) => {
      const { auth_token: token, data: user, code } = res;
      const data = {
        token,
        email: user?.email,
        first_name: user?.first_name,
        last_name: user?.last_name,
        company_id: user?.company_id,
        role: user?.role_id,
      };

      if (code === 200) {
        session.setSession(data);
      }
    });
  };

  return (
    <div className='auth-wrapper'>
      <div className='background'>
        <img src={bg} alt='Background' />
      </div>
      <div className='container-fluid vh-100 pt-4'>
        <div className='row'>
          <div className='col-12 mb-5'>
            <img
              src={logo}
              alt='Qatalyst logo'
              width={200}
            />
          </div>
          <div className='col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-1 col-xl-3 offset-xl-1 mt-5'>
            <Title level={1} className='mb-1'>Sign In</Title>
            <Text>Please login into your account</Text>
            <Form layout='vertical' className='login-form' onFinish={onSubmit}>
              <Form.Item
                name='email'
                label={t('Email')}
                className='mb-3'
                rules={[
                  { type: 'email', message: t('validation:invalid', { field: t('Email') }) },
                  { required: true, message: t('validation:required', { field: t('Email') }) },
                ]}
              >
                <Input
                  placeholder={t('placeholder:enter', { field: t('Email') })}
                  type='email'
                  autoFocus
                />
              </Form.Item>
              <Form.Item
                name='password'
                label={t('Password')}
                className='mb-2'
                rules={[
                  { required: true, message: t('validation:required', { field: t('Password') }) },
                ]}
              >
                <Input.Password
                  placeholder={t('placeholder:enter', { field: t('Password') })}
                  type='password'
                />
              </Form.Item>
              <Form.Item className='mb-2'>
                <Link to='/auth/forgot-password'>{t('Forgot Password')}</Link>
              </Form.Item>
              <Form.Item className='mb-0'>
                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  loading={loginState.isFetching}
                  block
                >
                  {t('Login')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Login;
