/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Space, Avatar, Typography, Dropdown, Menu } from 'antd';
import {
  DollarCircleFilled,
  CloudFilled,
  AppstoreOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';
import { CoalIcon } from '../CustomIcon';

const { Title } = Typography;

const NavInfo = ({ kurs, weather, isMobile }) => {
  const { t } = useTranslation();
  const usd_idr_diff = kurs?.usd_idr_diff || '-0';

  const renderInfo = () => {
    return (
      <Space size='large'>
        <div className='d-flex align-items-center'>
          <Avatar size={32} shape='square' icon={<CoalIcon />} className='avatar-coal' />
          <div className='ml-3'>
            <div>
              <Title level={5} className='mb-0' style={{ fontSize: 14 }}>{t('Price')}</Title>
              <p className='mb-0' style={{ fontSize: 13 }}>{parseFloat(kurs?.coal_price) || 0}</p>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <Avatar size={32} shape='square' icon={<DollarCircleFilled />} className='avatar-kurs' />
          <div className='ml-3'>
            <div>
              <Title level={5} className='mb-0' style={{ fontSize: 14 }}>{t('USD to IDR')}</Title>
              <div className='d-flex align-items-center' style={{ lineHeight: '21px' }}>
                <p
                  className='mb-0 mr-1'
                  style={{
                    fontSize: 13,
                    color: usd_idr_diff.startsWith('+') ? '#3CE3B1' : '#FF5E66',
                  }}
                >
                  {`Rp ${kurs.usd_idr || 0}`}
                </p>
                {usd_idr_diff.startsWith('+') ? <ArrowUpOutlined style={{ fontSize: 13, color: '#3CE3B1' }} /> : <ArrowDownOutlined className='' style={{ fontSize: 12, color: '#FF5E66' }} />}
              </div>
            </div>
          </div>
        </div>
        {/* <div className='d-flex align-items-center'>
          <Avatar size={32} shape='square' icon={<CloudFilled />} className='avatar-weather' />
          <div className='ml-3'>
            <div>
              <Title level={5} className='mb-0' style={{ fontSize: 14 }}>{t('Weather')}</Title>
              <p className='mb-0' style={{ fontSize: 13 }}>{`${weather?.main?.temp || 0} °C`}</p>
            </div>
          </div>
        </div> */}
      </Space>
    );
  };

  if (!isMobile) {
    return (
      <div className='nav-info'>
        {renderInfo()}
      </div>
    );
  }

  return (
    <Dropdown placement='bottomRight' trigger={['click']} overlay={renderInfo}>
      <Menu className='d-flex align-item-center' mode='horizontal'>
        <Menu.Item key='info'>
          <a href='#/' onClick={(e) => e.preventDefault()}>
            <AppstoreOutlined className='nav-icon mr-0' />
          </a>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

NavInfo.propTypes = {
  kurs: PropTypes.objectOf(PropTypes.any).isRequired,
  weather: PropTypes.objectOf(PropTypes.any).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default NavInfo;
