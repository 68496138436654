import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';

import Loading from 'App/Components/Loading';

const Dashboard = React.lazy(() => import('App/Containers/Dashboard'));
const InputData = React.lazy(() => import('App/Containers/InputData'));
const Report = React.lazy(() => import('App/Containers/Reporting'));
const MasterData = React.lazy(() => import('App/Containers/MasterData'));
const UserManagement = React.lazy(() => import('App/Containers/UserManagement'));
const Setting = React.lazy(() => import('App/Containers/Setting'));
const Logs = React.lazy(() => import('App/Containers/Logs'));
const Error = React.lazy(() => import('App/Containers/Error'));

function Routes() {
  return (
    <Suspense fallback={<Loading />}>
      <Route path='/dashboard' component={Dashboard} />
      <Route path='/input-data' component={InputData} />
      <Route path='/report' component={Report} />
      <Route path='/master-data' component={MasterData} />
      <Route path='/user-management' component={UserManagement} />
      <Route path='/setting' component={Setting} />
      <Route path='/logs' component={Logs} />
      <Route path='/error' component={Error} />
    </Suspense>
  );
}

export default Routes;
