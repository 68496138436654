import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import UIContext from 'App/Contexts/UI';
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH } from 'App/Constants/ThemeConstant';

import { Layout } from 'antd';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import Logo from './Logo';
import NavInfo from './NavInfo';
import NavLanguage from './NavLanguage';

const { Header } = Layout;

const HeaderNav = ({ isMobile, kurs, weather, loadingLogo, logo }) => {
  const ui = useContext(UIContext);

  const getNavWidth = () => {
    if (isMobile) {
      return '0px';
    }

    if (ui.collapsedMenu) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }

    return `${SIDE_NAV_WIDTH}px`;
  };

  const onToggle = () => {
    ui.onMobileNavToggle(!ui.mobileNav);
  };

  return (
    <Header className='app-header dark'>
      <div className='app-header-wrapper'>
        <Logo
          collapsed={ui.collapsedMenu}
          loading={loadingLogo}
          logo={logo}
        />
        <div className='nav' style={{ width: `calc(100% - ${getNavWidth()})` }}>
          {isMobile ? (
            <div className='nav-left'>
              <ul className='ant-menu ant-menu-root ant-menu-horizontal'>
                <li className='ant-menu-item ant-menu-item-only-child' key='toggle'>
                  <MenuUnfoldOutlined className='nav-icon' onClick={onToggle} />
                </li>
              </ul>
            </div>
          ) : null}
          <div className='nav-right'>
            <NavInfo
              kurs={kurs}
              weather={weather}
              isMobile={isMobile}
            />
            <NavLanguage />
          </div>
        </div>
      </div>
    </Header>
  );
};

HeaderNav.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  kurs: PropTypes.objectOf(PropTypes.any).isRequired,
  weather: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingLogo: PropTypes.bool.isRequired,
  logo: PropTypes.string,
};

HeaderNav.defaultProps = {
  logo: null,
};

export default HeaderNav;
