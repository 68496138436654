import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAxios from 'App/Hooks/useAxios';

import SessionContext from 'App/Contexts/Session';

function SessionContextProvider({ children }) {
  const initializedToken = localStorage.getItem('token') || null;

  const [state, setState] = useState({
    authenticated: false,
    token: initializedToken,
    email: null,
    first_name: null,
    last_name: null,
    company_id: null,
    role: null,
    logo: null,
  });

  /* eslint no-unused-vars: "off" */
  const [activeState, activeSend] = useAxios({
    url: 'api/users/me',
  });

  // Method to handle token update
  useEffect(() => {
    if (state.token !== initializedToken) {
      localStorage.setItem('token', state.token);
    }

    if (state.token) {
      activeSend({
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }, ({ data }) => {
        setState((prevState) => ({
          ...prevState,
          authenticated: true,
          email: data?.email,
          first_name: data?.first_name,
          last_name: data?.last_name,
          company_id: data?.company_id,
          role: data?.role_name,
          logo: data?.logo || null,
        }));
      });
    }
  }, [state.token]);

  const sessionLogout = () => {
    setState({
      authenticated: false,
      token: null,
      email: null,
      first_name: null,
      last_name: null,
      company_id: null,
      role: null,
      logo: null,
    });

    localStorage.removeItem('token');
    localStorage.removeItem('email');
  };

  return (
    <SessionContext.Provider
      value={{
        ...state,
        setSession: setState,
        sessionLogout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

SessionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SessionContextProvider;
