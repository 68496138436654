import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SessionContext from 'App/Contexts/Session';
import UIContext from 'App/Contexts/UI';

import { Drawer, Button, Avatar, Menu, Dropdown } from 'antd';
import { MoreOutlined, LogoutOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars';

import Logo from './Logo';
import MenuLoading from '../MenuLoading';
import MenuContent from './MenuContent';

const MobileNav = ({ history, loading, loadingLogo, logo, menu, openChangePassword }) => {
  const session = useContext(SessionContext);
  const ui = useContext(UIContext);
  const { t } = useTranslation();

  const initialName = (name) => {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  };

  const onClose = () => {
    ui.onMobileNavToggle(false);
  };

  const renderSetting = () => {
    return (
      <Menu>
        <Menu.Item
          key='change-password'
          icon={<EditOutlined />}
          onClick={() => openChangePassword()}
        >
          {t('Change Password')}
        </Menu.Item>
        <Menu.Item
          key='logout'
          icon={<LogoutOutlined />}
          onClick={() => session.sessionLogout()}
        >
          {t('Logout')}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Drawer
      placement='left'
      closable={false}
      onClose={onClose}
      visible={ui.mobileNav}
      bodyStyle={{ padding: 0 }}
    >
      <div className='d-flex flex-column h-100'>
        <div className='d-flex justify-content-between align-items-center' style={{ backgroundColor: '#d5af5a' }}>
          <Logo
            collapsed={ui.collapsedMenu}
            loading={loadingLogo}
            logo={logo}
            mobileLogo
          />
          <Button size='small' type='text' onClick={() => onClose()}>
            <CloseOutlined />
          </Button>
        </div>
        <div className='mobile-nav-menu'>
          <div className='profile'>
            <div className='d-flex align-items-start justify-content-between'>
              <div className='d-flex align-items-center'>
                <Avatar size={45} style={{ backgroundColor: '#1890ff' }}>
                  {initialName(`${session.first_name} ${session.last_name}`)}
                </Avatar>
                <div className='pl-3'>
                  <h4 className='name' title={`${session.first_name} ${session.last_name}`}>{`${session.first_name} ${session.last_name}`}</h4>
                  <span className='text-white'>{session.role || '-'}</span>
                </div>
              </div>
              <Dropdown placement='bottomRight' trigger={['click']} overlay={renderSetting}>
                <a href='#/' className='mt-1 ml-auto' onClick={(e) => e.preventDefault()}>
                  <MoreOutlined style={{ fontSize: '1.2rem' }} />
                </a>
              </Dropdown>
            </div>
          </div>
          {loading ? (
            <MenuLoading />
          ) : (
            <Scrollbars autoHide>
              <MenuContent
                history={history}
                menu={menu}
              />
            </Scrollbars>
          )}
        </div>
      </div>
    </Drawer>
  );
};

MobileNav.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  loadingLogo: PropTypes.bool.isRequired,
  logo: PropTypes.string,
  menu: PropTypes.arrayOf(PropTypes.any).isRequired,
  openChangePassword: PropTypes.func.isRequired,
};

MobileNav.defaultProps = {
  logo: null,
};

export default MobileNav;
