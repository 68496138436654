import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, Menu } from 'antd';

import ID from 'App/Assets/Images/flags/id.png';
import EN from 'App/Assets/Images/flags/us.png';

const NavLanguage = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem('locale') || 'en');
  const locales = ['en', 'id'];
  const languageLabels = {
    en: 'English',
    id: 'Indonesia',
  };
  const languageIcons = {
    en: EN,
    id: ID,
  };

  const changeLang = (val) => {
    setLang(val.key);
    i18n.changeLanguage(val.key);
    localStorage.setItem('locale', val.key);
  };

  const langMenu = (
    <Menu selectedKeys={[lang]} onClick={changeLang}>
      {locales.map((locale) => (
        <Menu.Item key={locale}>
          <img src={languageIcons[locale]} alt={languageLabels[locale]} className='mr-2' style={{ maxWidth: '20px' }} />
          {languageLabels[locale]}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown placement='bottomRight' trigger={['click']} overlay={langMenu}>
      <Menu className='d-flex align-item-center' mode='horizontal'>
        <Menu.Item key='language'>
          <a href='#/' onClick={(e) => e.preventDefault()}>
            <img
              src={languageIcons[lang]}
              alt={languageLabels[lang]}
              className='mr-0'
              style={{ marginBottom: '6px' }}
            />
          </a>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavLanguage;
