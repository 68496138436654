import 'core-js';
import 'regenerator-runtime';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

// Import app
import App from 'App/Containers/App';
// Import Component
import Loading from 'App/Components/Loading';
// Import Context
import SessionProvider from 'App/Providers/SessionProvider';
import UIProvider from 'App/Providers/UIProvider';

// Define Root application
const Root = () => (
  <UIProvider>
    <SessionProvider>
      <Suspense fallback={<Loading isFull={false} />}>
        <App />
      </Suspense>
    </SessionProvider>
  </UIProvider>
);

// Render App
ReactDOM.render(<Root />, document.querySelector('#app'));
