import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SessionContext from 'App/Contexts/Session';
import UIContext from 'App/Contexts/UI';

import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH } from 'App/Constants/ThemeConstant';

import { Layout, Avatar, Dropdown, Menu } from 'antd';
import { MoreOutlined, EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars';

import MenuLoading from '../MenuLoading';
import MenuContent from './MenuContent';

const { Sider } = Layout;

const SideNav = ({ history, loading, menu, openChangePassword }) => {
  const session = useContext(SessionContext);
  const ui = useContext(UIContext);
  const { t } = useTranslation();

  const initialName = (name) => {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  };

  const renderSetting = () => {
    return (
      <Menu>
        <Menu.Item
          key='change-password'
          icon={<EditOutlined />}
          onClick={() => openChangePassword()}
        >
          {t('Change Password')}
        </Menu.Item>
        <Menu.Item
          key='logout'
          icon={<LogoutOutlined />}
          onClick={() => session.sessionLogout()}
        >
          {t('Logout')}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Sider
      className='side-nav side-nav-dark'
      width={SIDE_NAV_WIDTH}
      collapsed={ui.collapsedMenu}
      collapsedWidth={SIDE_NAV_COLLAPSED_WIDTH}
    >
      <div className='profile'>
        <div className='d-flex align-items-start justify-content-between'>
          <div className='d-flex align-items-center'>
            <Avatar size={45} style={{ backgroundColor: '#1890ff' }}>
              {initialName(`${session.first_name} ${session.last_name}`)}
            </Avatar>
            <div className='pl-3'>
              <h4 className='name' title={`${session.first_name} ${session.last_name}`}>{`${session.first_name} ${session.last_name}`}</h4>
              <span className='text-white'>{session.role || '-'}</span>
            </div>
          </div>
          <Dropdown
            placement='bottomRight'
            trigger={['click']}
            overlay={renderSetting}
          >
            <a href='#/' className='mt-1 ml-auto' onClick={(e) => e.preventDefault()}>
              <MoreOutlined style={{ fontSize: '1.2rem' }} />
            </a>
          </Dropdown>
        </div>
      </div>
      {loading ? (
        <MenuLoading />
      ) : (
        <Scrollbars autoHide>
          <MenuContent
            history={history}
            menu={menu}
          />
        </Scrollbars>
      )}
    </Sider>
  );
};

SideNav.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  menu: PropTypes.arrayOf(PropTypes.any).isRequired,
  openChangePassword: PropTypes.func.isRequired,
};

export default SideNav;
